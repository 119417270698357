import { IconButton, IconButtonProps } from '@material-ui/core';
import { MessageRounded } from '@material-ui/icons';
import React, { FC, Fragment } from 'react';
import useDialog from 'src/hooks/useDialog';

import { useTypedSelector } from 'src/redux';
import { selectById } from 'src/redux/entities/simulationResults/selectors';
import ResultsDialog from '../ResultsDialog';

type Props = IconButtonProps & {
  lastResultId: number;
};

const ResultsButton: FC<Props> = ({ lastResultId, ...rest }) => {
  const { open, handleOpen, handleClose } = useDialog();

  const simulationResult = useTypedSelector((state) =>
    selectById(state, lastResultId),
  )!;

  return (
    <Fragment>
      <IconButton {...rest} onClick={handleOpen}>
        <MessageRounded />
      </IconButton>

      <ResultsDialog
        handleClose={handleClose}
        open={open}
        simulationResult={simulationResult}
      />
    </Fragment>
  );
};

export default ResultsButton;
