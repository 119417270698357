import { TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

const HeadRow: FC = () => (
  <TableRow>
    <TableCell align="left">Configuration name</TableCell>
    <TableCell>Execution date</TableCell>
    <TableCell>Execution duration</TableCell>
    <TableCell>Number of events</TableCell>
    <TableCell align="right">Actions</TableCell>
  </TableRow>
);

export default HeadRow;
