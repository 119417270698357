import React, { FC } from 'react';
import clsx from 'clsx';
import {
  Dialog as MuiDialog,
  DialogProps,
  IconButton,
} from '@material-ui/core';

import { useStyles } from './styles';
import CloseIcon from './CloseIcon';

type Props = DialogProps & {
  onClose(event: any): void;
  open: boolean;
  className?: string;
};

const Dialog: FC<Props> = ({ children, open, className, onClose }) => {
  const classes = useStyles();

  return (
    <MuiDialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      <div className={clsx(classes.wrapper, className)}>
        <IconButton
          className={classes.closeIcon}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        {children}
      </div>
    </MuiDialog>
  );
};

export default Dialog;
