import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: theme.breakpoints.values.lg,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  }),
  { name: 'PageContent' },
);
