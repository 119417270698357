import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { useField } from 'exeform';
import React, { FC, SyntheticEvent, useCallback } from 'react';
import { useStyles } from './styles';

type Props = {
  name: string;
};

const FileInput: FC<Props> = ({ name }) => {
  const classes = useStyles();
  const { meta, helpers, field } = useField(name);
  const error = meta.touched ? meta.error : null;

  const handleChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      helpers.setValue(file);
      helpers.setTouched(true);
    },
    [helpers],
  );

  return (
    <>
      <div className={classes.root}>
        <Box clone mr={2}>
          <Button
            variant="outlined"
            component="label"
            startIcon={<AddCircle />}
            onClick={(event: SyntheticEvent) => {
              event.stopPropagation();
            }}
          >
            Choose simulation
            <input
              type="file"
              accept="zip,application/zip,application/x-zip,application/x-zip-compressed"
              style={{ display: 'none' }}
              onChange={handleChange}
            />
          </Button>
        </Box>
        {field.value && (
          <Typography variant="h6" color="primary">
            {field.value.name}
          </Typography>
        )}
      </div>

      {error && <FormHelperText error={Boolean(error)}>{error}</FormHelperText>}
    </>
  );
};

export default FileInput;
