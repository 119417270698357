import React, { FC } from 'react';

const ErrorIcon: FC = () => (
  <svg width={11} height={11} viewBox="0 0 11 11">
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M2.318 8.682l6.364-6.364M2.318 2.318l6.364 6.364" />
    </g>
  </svg>
);

export default ErrorIcon;
