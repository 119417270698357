import { useCallback, ChangeEvent, useRef } from 'react';
import { useRouter, useRouterState } from '@routo/react';
import { omit } from 'remeda';

const usePagination = (count?: number) => {
  const router = useRouter();
  const state = useRouterState();
  const { page } = state.queryParams;
  const prevCountRef = useRef(count);

  if (count) {
    prevCountRef.current = count;
  }

  const handleChangePage = useCallback(
    (event: ChangeEvent<unknown>, page: number) => {
      const { id, queryParams, params } = router.getState();

      const newQueryParams =
        page > 1 ? { ...queryParams, page } : omit(queryParams, ['page']);

      router.push(id, { params, queryParams: newQueryParams });
    },
    [router],
  );

  return {
    page: page ? parseInt(page, 10) : 1,
    handleChangePage,
    count: prevCountRef.current,
  };
};

export default usePagination;
