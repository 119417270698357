import { Box } from '@material-ui/core';
import { Link } from '@routo/link';
import React, { FC } from 'react';
import { SIMULATIONS } from 'src/router/ids';

import { useStyles } from './styles';

const Logo: FC = () => {
  const classes = useStyles();

  return (
    <Link
      to={SIMULATIONS}
      className={classes.root}
      activeClassName={classes.disabled}
    >
      <Box clone height={36}>
        <img src={require('./logo.png')} alt="Rap Fame" height="100%" />
      </Box>
    </Link>
  );
};

export default Logo;
