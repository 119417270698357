import { Paper, TableContainer } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import Page from 'src/components/Page';
import PageContent from 'src/components/PageContent';
import Pagination from 'src/components/Pagination';
import Table from 'src/components/Table';

import { useEffectState } from 'src/hooks/useAsyncEffect';
import usePagination from 'src/hooks/usePagination';
import useTitle from 'src/hooks/useTitle';
import { useTypedSelector } from 'src/redux';
import { useFindHistory } from 'src/redux/entities/history/hooks';
import { selectIds } from 'src/redux/entities/history/selectors';

import ContentRow from './ContentRow';
import HeadRow from './HeadRow';
import { useStyles } from './styles';

const History: FC = () => {
  useTitle('History');

  const classes = useStyles();
  const effect = useFindHistory();
  const { pending, data } = useEffectState(effect);
  const { page, handleChangePage, count } = usePagination(data?.pages);

  const historyIds = useTypedSelector(selectIds);

  useEffect(() => {
    effect.run({ pageNumber: page });
  }, [effect, page]);

  return (
    <Page>
      <PageContent>
        <TableContainer component={Paper}>
          <Table
            ids={historyIds as number[]}
            isLoading={pending}
            HeadRow={HeadRow}
            ContentRow={ContentRow}
          />
        </TableContainer>
        {Boolean(historyIds) && !pending && (
          <Pagination
            count={count}
            page={page}
            className={classes.pagination}
            onChange={handleChangePage}
          />
        )}
      </PageContent>
    </Page>
  );
};

export default History;
