import { useEffect } from 'react';

const base = 'Ramsey Systems';

const useTitle = (title: string) =>
  useEffect(() => {
    document.title = `${title} - ${base}`;

    return () => {
      document.title = base;
    };
  }, [title]);

export default useTitle;
