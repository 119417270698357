import { useDispatch } from 'react-redux';

import { useAsyncEffect } from 'src/hooks/useAsyncEffect';
import { useTypedSelector } from 'src/redux';
import { SIMULATION_VERSIONS } from 'src/redux/entities/simulations/const';
import { selectById } from 'src/redux/entities/simulations/selectors';
import simulationsSlice from 'src/redux/entities/simulations/slice';

import { saveEntities } from '../actions';
import { CreateSimulationValues } from '../types';
import api from './api';
import { ID, SIMULATION, SIMULATION_VERSION } from './const';
import slice from './slice';

export const useFindSimulationsVersion = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (ids: number[]) => {
    const { payload } = await api.find({
      'id[in]': ids.join(','),
      include: 'last_result',
    });

    dispatch(saveEntities(payload));
  });
};

export const useCreateSimulationVersion = (id: number) => {
  const dispatch = useDispatch();
  const simulation = useTypedSelector((state) => selectById(state, id))!;

  return useAsyncEffect(async (values: CreateSimulationValues) => {
    const response = await api.createRecord({
      [SIMULATION_VERSION]: {
        ...values,
        [SIMULATION]: id,
      },
    });

    dispatch(saveEntities(response));

    dispatch(
      simulationsSlice.actions.updateSimulation({
        id,
        changes: {
          [SIMULATION_VERSIONS]: [
            response[SIMULATION_VERSION][0][ID],
            ...simulation[SIMULATION_VERSIONS],
          ],
        },
      }),
    );
  });
};

export const useDeleteSimulationVersion = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (id: number) => {
    await api.deleteRecord(id);

    dispatch(slice.actions.deleteSimulationVersion(id));
  });
};
