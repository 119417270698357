import {
  Pagination as MuiPagination,
  PaginationItem,
  PaginationProps,
} from '@material-ui/lab';
import React, { FC } from 'react';

const Pagination: FC<PaginationProps> = ({ count, className, ...rest }) => {
  if (!count || count === 1) {
    return null;
  }

  return (
    <MuiPagination
      {...rest}
      className={className}
      siblingCount={2}
      size="large"
      count={count}
      renderItem={(item) => <PaginationItem {...item} color="standard" />}
    />
  );
};

export default Pagination;
