import { SyntheticEvent, useCallback, useState } from 'react';

const useDialog = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    return setOpen(true);
  }, []);

  const handleClose = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    return setOpen(false);
  }, []);

  return { open, handleOpen, handleClose };
};

export default useDialog;
