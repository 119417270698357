import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './styles';

type Props = CircularProgressProps;

const Spinner: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress {...props} />
    </div>
  );
};

export default Spinner;
