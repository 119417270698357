import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    text: {
      fontWeight: 400,
    },

    dimmed: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  { name: 'ResultsDialog' },
);
