import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    row: {
      whiteSpace: 'nowrap',
    },

    text: {
      fontWeight: 400,
    },
  },
  { name: 'LastResultsCell' },
);
