import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    noWrap: {
      whiteSpace: 'nowrap',
    },
  },
  { name: 'ContentRow' },
);
