import React, { FC, Fragment, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  IconButtonProps,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import useDialog from 'src/hooks/useDialog';

type Props = IconButtonProps & {
  action(): void;
  disabled: boolean;
};

const RemoveButton: FC<Props> = ({ action, disabled, ...rest }) => {
  const { open, handleClose, handleOpen } = useDialog();

  const handleSubmit = useCallback(
    (event) => {
      event.stopPropagation();
      action();
    },
    [action],
  );

  return (
    <Fragment>
      <IconButton {...rest} onClick={handleOpen}>
        <Delete />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Deleting</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete this simulation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={disabled}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default RemoveButton;
