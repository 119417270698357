import { TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

const NestedHeaderRow: FC = () => {
  return (
    <TableRow>
      <TableCell align="right" padding="none">
        Version
      </TableCell>
      <TableCell>Date Created</TableCell>
      <TableCell>Last results</TableCell>
      <TableCell align="center">Results</TableCell>
      <TableCell align="center">Description</TableCell>
      <TableCell align="right">Actions</TableCell>
    </TableRow>
  );
};

export default NestedHeaderRow;
