import React, { FC } from 'react';
import useFullHeight from 'src/hooks/useFullHeight';

import { useStyles } from './styles';

const FullHeight: FC = ({ children }) => {
  const height = useFullHeight();
  const classes = useStyles(height);

  return <div className={classes.root}>{children}</div>;
};

export default FullHeight;
