import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      display: 'inline-flex',
      flexDirection: 'column',
      color: '#a74028',
      fontWeight: 700,
      transition: `opacity ${theme.transitions.duration.shortest}ms`,

      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },

      '&:hover': {
        opacity: 0.6,
      },

      '&::after': {
        content: '""',
        borderBottom: '2px solid transparent',
        transition: `border-color ${theme.transitions.duration.shortest}ms`,
      },
    },

    active: {
      pointerEvents: 'none',

      '&::after': {
        borderColor: '#a74028',
      },
    },
  }),
  { name: 'Link' },
);
