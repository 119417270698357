import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    wrapper: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  { name: 'Spinner' },
);
