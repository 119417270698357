export const createFormData = (file: File, fields: object): FormData => {
  const formData = new FormData();

  for (const [key, val] of Object.entries(fields)) {
    formData.append(key, val);
  }

  formData.append('file', file);

  return formData;
};
