import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    table: {
      // @ts-ignore
      height: (height: number) => height,
    },
  },
  { name: 'Simulations' },
);
