import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    logoWrapper: {
      flexGrow: 1,
      display: 'flex',
    },
  },
  { name: 'Header' },
);
