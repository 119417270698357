import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button as MuiButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { Form } from 'exeform';
import React, { FC, Fragment, SyntheticEvent, useCallback } from 'react';

import { Effect } from 'src/hooks/useAsyncEffect';
import useDialog from 'src/hooks/useDialog';
import { CreateSimulationValues } from 'src/redux/entities/types';

import FileInput from '../FileInput';
import InputField from '../InputField';
import Spinner from '../Spinner';
import SubmitButton from '../SubmitButton';

import { useData } from './hooks';
import { useStyles } from './styles';

type Props = {
  id?: number;
  Button: FC<{ onClick(event: SyntheticEvent): void }>;
  useCustomEffect(id?: number): Effect<CreateSimulationValues, void>;
};

const AddSimulationButton: FC<Props> = ({
  id,
  Button,
  children,
  useCustomEffect,
  ...rest
}) => {
  const classes = useStyles();

  const { open, handleOpen, handleClose } = useDialog();
  const { handleSubmit, pending, form } = useData(useCustomEffect(id));

  const closeDialog = useCallback(
    (event: SyntheticEvent) => {
      handleClose(event);
      form.reset();
    },
    [form, handleClose],
  );

  return (
    <Fragment>
      <Button {...rest} onClick={handleOpen}>
        {children}
      </Button>

      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
        <DialogContent className={clsx(pending && classes.dimmed)}>
          <Form form={form} onSubmit={handleSubmit}>
            <Box mb={2}>
              <InputField fullWidth name="name" label="Simulation name" />
            </Box>

            <Box mb={2}>
              <InputField
                multiline
                fullWidth
                name="description"
                label="Simulation description"
              />
            </Box>

            <FileInput name="file" />

            <Box mt={5}>
              <DialogActions>
                <MuiButton onClick={closeDialog} variant="outlined">
                  Cancel
                </MuiButton>
                <SubmitButton disabled={pending}>Save simulation</SubmitButton>
              </DialogActions>
            </Box>
          </Form>
        </DialogContent>
        {pending && <Spinner />}
      </Dialog>
    </Fragment>
  );
};

export default AddSimulationButton;
