import { IconButton } from '@material-ui/core';
import { PlayCircleFilledOutlined } from '@material-ui/icons';
import React, { FC, Fragment, SyntheticEvent, useCallback } from 'react';

import { Effect, useEffectState } from 'src/hooks/useAsyncEffect';
import useDialog from 'src/hooks/useDialog';

import ResultsDialog from '../ResultsDialog';

type Props = {
  id: number;
  runEffect: Effect<number, any>;
};

const RunSimulationButton: FC<Props> = ({ id, runEffect }) => {
  const { open, handleOpen, handleClose } = useDialog();
  const { pending, data } = useEffectState(runEffect);

  const handleRun = useCallback(
    (event: SyntheticEvent) => {
      runEffect.run(id);
      handleOpen(event);
    },
    [runEffect, id, handleOpen],
  );

  return (
    <Fragment>
      <IconButton onClick={handleRun} disabled={pending}>
        <PlayCircleFilledOutlined />
      </IconButton>

      <ResultsDialog
        handleClose={handleClose}
        open={open}
        isLoading={pending}
        simulationResult={data || null}
      />
    </Fragment>
  );
};

export default RunSimulationButton;
