import React, { FC } from 'react';
import { useField } from 'exeform';

import ErrorIcon from './ErrorIcon';
import { TextField, TextFieldProps } from '@material-ui/core';

type Props = TextFieldProps & {
  name: string;
  className?: string;
  withError?: boolean;
};

const InputField: FC<Props> = (props) => {
  const { name, withError = false, className, ...other } = props;
  const { field, meta } = useField(name);
  const error = meta.touched ? meta.error : null;

  return (
    <div>
      <TextField
        {...other}
        {...field}
        error={Boolean(error)}
        helperText={error}
      />

      {error && withError ? (
        <p>
          <ErrorIcon />
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default InputField;
