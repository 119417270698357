import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    pagination: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { name: 'History' },
);
