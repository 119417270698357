import {
  Box,
  Button,
  ButtonProps,
  Paper,
  TableContainer,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import React, { FC, useEffect, useRef } from 'react';
import AddSimulationButton from 'src/components/AddSimulationButton';
import Page from 'src/components/Page';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import { useEffectState } from 'src/hooks/useAsyncEffect';
import useTitle from 'src/hooks/useTitle';
import { useTypedSelector } from 'src/redux';
import { useFindSimulations } from 'src/redux/entities/simulations/hooks';
import { useCreateSimulation } from 'src/redux/entities/simulations/hooks';
import { selectIds } from 'src/redux/entities/simulations/selectors';

import ContentRow from './ContentRow';
import HeadRow from './HeadRow';
import { useStyles } from './styles';
import { stickToBottom } from './utils';

const Simulations: FC = () => {
  useTitle('Simulations');
  const tableRef = useRef<HTMLElement | null>(null);
  const height = stickToBottom(tableRef.current);

  const classes = useStyles(height);

  const effect = useFindSimulations();
  const { pending } = useEffectState(effect);

  const simulationsIds = useTypedSelector(selectIds);

  useEffect(() => {
    effect.run({});
  }, [effect]);

  return (
    <Page>
      <PageContent>
        <Box clone mb={2}>
          <AddSimulationButton
            useCustomEffect={useCreateSimulation}
            Button={(prop: ButtonProps) => (
              <Button
                {...prop}
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
              />
            )}
          >
            Add simulation to the list
          </AddSimulationButton>
        </Box>

        <TableContainer
          component={Paper}
          className={classes.table}
          ref={tableRef}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            ids={simulationsIds as number[]}
            isLoading={pending}
            HeadRow={HeadRow}
            ContentRow={ContentRow}
          />
        </TableContainer>
      </PageContent>
    </Page>
  );
};

export default Simulations;
