import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    root: {
      display: 'inline-flex',
    },

    disabled: {
      pointerEvents: 'none',
    },
  },
  { name: 'Logo' },
);
