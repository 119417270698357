import { useDispatch } from 'react-redux';
import { useAsyncEffect } from 'src/hooks/useAsyncEffect';
import { saveEntities } from 'src/redux/entities/actions';
import { LAST_RESULT } from 'src/redux/entities/simulationVersions/const';
import simulationVersionsSlice from 'src/redux/entities/simulationVersions/slice';
import simulationsSlice from 'src/redux/entities/simulations/slice';

import api from './api';
import { ID, SIMULATION_RESULT, SIMULATION_VERSION } from './const';

export const useFindSimulationResults = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (ids: number[]) => {
    const { payload } = await api.find({
      'id[in]': ids.join(','),
    });

    dispatch(saveEntities(payload));
  });
};

export const useRunSimulationVersion = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (id: number) => {
    const response = await api.createRecord({
      [SIMULATION_RESULT]: { [SIMULATION_VERSION]: id },
    });

    dispatch(saveEntities(response));

    dispatch(
      simulationVersionsSlice.actions.updateSimulationVersion({
        id,
        changes: {
          [LAST_RESULT]: response[SIMULATION_RESULT][0][ID],
        },
      }),
    );

    dispatch(
      simulationsSlice.actions.updateSimulation({
        id,
        changes: {
          [LAST_RESULT]: response[SIMULATION_RESULT][0][ID],
        },
      }),
    );

    return response[SIMULATION_RESULT][0];
  });
};

export const useRunSimulation = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (id: number) => {
    const response = await api.createRecord({
      [SIMULATION_RESULT]: { [SIMULATION_VERSION]: id },
    });

    dispatch(saveEntities(response));

    return response[SIMULATION_RESULT][0];
  });
};
