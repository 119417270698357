import { TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

import ResultsButton from 'src/components/ResultsButton';
import RunSimulationButton from 'src/components/RunSimulationButton';
import { useTypedSelector } from 'src/redux';
import {
  NAME,
  SIMULATION_RESULT,
  SIMULATION_VERSION,
} from 'src/redux/entities/history/const';
import { selectById as selectHistoryById } from 'src/redux/entities/history/selectors';
import { selectById as selectSimulationVersionById } from 'src/redux/entities/simulationVersions/selectors';
import {
  EVENTS_NUMBER,
  EXECUTION_DATE,
  EXECUTION_DURATION,
} from 'src/redux/entities/simulationResults/const';
import { useRunSimulationVersion } from 'src/redux/entities/simulationResults/hooks';
import { selectById as selectResultById } from 'src/redux/entities/simulationResults/selectors';
import { formatDate, formatToSeconds } from 'src/utils/format';

import { useStyles } from './styles';

type Props = {
  id: number;
};

const ContentRow: FC<Props> = ({ id }) => {
  const classes = useStyles();

  const runEffect = useRunSimulationVersion();

  const simulationHistory = useTypedSelector((state) =>
    selectHistoryById(state, id),
  )!;

  const simulationVersion = useTypedSelector((state) =>
    selectSimulationVersionById(state, simulationHistory.simulation_version),
  )!;

  const simulationResult = useTypedSelector((state) =>
    selectResultById(state, simulationHistory[SIMULATION_RESULT]),
  )!;

  return (
    <TableRow>
      <TableCell>
        {simulationHistory[NAME]} v.{simulationVersion.version}
      </TableCell>
      <TableCell>{formatDate(simulationResult[EXECUTION_DATE])}</TableCell>

      <TableCell>
        {formatToSeconds(simulationResult[EXECUTION_DURATION])}
      </TableCell>
      <TableCell align="center">{simulationResult[EVENTS_NUMBER]}</TableCell>
      <TableCell align="right" className={classes.noWrap}>
        <ResultsButton lastResultId={simulationHistory[SIMULATION_RESULT]} />

        <RunSimulationButton
          id={simulationHistory[SIMULATION_VERSION]}
          runEffect={runEffect}
        />
      </TableCell>
    </TableRow>
  );
};

export default ContentRow;
