import { TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

import RemoveButton from 'src/components/RemoveButton';
import ResultsButton from 'src/components/ResultsButton';
import RunSimulationButton from 'src/components/RunSimulationButton';
import { useEffectState } from 'src/hooks/useAsyncEffect';
import { useTypedSelector } from 'src/redux';
import { useRunSimulationVersion } from 'src/redux/entities/simulationResults/hooks';
import {
  DATE_CREATED,
  LAST_RESULT,
  NAME,
  VERSION,
} from 'src/redux/entities/simulationVersions/const';
import { useDeleteSimulationVersion } from 'src/redux/entities/simulationVersions/hooks';
import { selectById } from 'src/redux/entities/simulationVersions/selectors';
import { formatDate } from 'src/utils/format';

import LastResultsCell from '../LastResultsCell';
import { useStyles } from './styles';

type Props = {
  id: number;
  onlyOneRow?: boolean;
};

const NestedContentRow: FC<Props> = ({ id, onlyOneRow }) => {
  const classes = useStyles();
  const deleteEffect = useDeleteSimulationVersion();
  const runEffect = useRunSimulationVersion();
  const { pending } = useEffectState(deleteEffect);

  const simulationVersion = useTypedSelector((state) => selectById(state, id))!;

  return (
    <TableRow hover>
      <TableCell component="th" scope="row" align="right" padding="none">
        {simulationVersion[NAME]} v.{simulationVersion[VERSION]}
      </TableCell>
      <TableCell>{formatDate(simulationVersion[DATE_CREATED])}</TableCell>

      <LastResultsCell id={simulationVersion[LAST_RESULT]} />

      <TableCell align="center">
        {simulationVersion[LAST_RESULT] && (
          <ResultsButton lastResultId={simulationVersion[LAST_RESULT]} />
        )}
      </TableCell>
      <TableCell>{simulationVersion.description}</TableCell>
      <TableCell align="right" className={classes.noWrap}>
        <RunSimulationButton id={id} runEffect={runEffect} />

        {!onlyOneRow && (
          <RemoveButton
            action={() => deleteEffect.run(id)}
            disabled={pending}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default NestedContentRow;
