import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      // @ts-ignore
      maxHeight: (height: number) => height,
    },
  },
  { name: 'FullHeight' },
);
