import React, { FC } from 'react';
import { Box } from '@material-ui/core';

type Props = {
  className?: string;
};

const DialogContent: FC<Props> = ({ children, className }) => (
  <Box flexGrow={1} className={className}>
    {children}
  </Box>
);

export default DialogContent;
