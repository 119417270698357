export const SIMULATION_RESULT = 'simulation_result';

export const ID = 'id';
export const DESCRIPTION = 'description';
export const EXECUTION_DATE = 'execution_date';
export const EXECUTION_DURATION = 'execution_duration';
export const EVENTS_NUMBER = 'events_number';
export const VERSION = 'version';
export const NAME = 'name';
export const S3_URL = 's3_url';
export const SIMULATION_VERSION = 'simulation_version';
export const SIMULATION = 'simulation';
