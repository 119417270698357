import { useDispatch } from 'react-redux';

import { useAsyncEffect } from 'src/hooks/useAsyncEffect';
import getIds from 'src/utils/getIds';
import { getPagesCount } from 'src/utils/pagination';
import { saveEntities } from '../actions';

import api from './api';
import { SIMULATION_HISTORY } from './const';

type Params = {
  pageNumber: number;
};

export const useFindHistory = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async ({ pageNumber }: Params) => {
    const limit = 10;

    const { payload, meta } = await api.find({
      offset: pageNumber > 1 ? pageNumber * limit : 0,
      limit,
      include: 'simulation_version,simulation_result',
    });

    dispatch(saveEntities(payload));

    return {
      pages: getPagesCount(meta.total!, limit),
      ids: getIds(payload[SIMULATION_HISTORY]),
    };
  });
};
