import { useEffect, useState } from 'react';

import debounce from 'src/utils/debounce';

type Listener = (value: number) => void;

const createHeightObserver = () => {
  let value = window.innerHeight;
  const listeners: Listener[] = [];

  const notify = () => {
    const currentListeners = listeners.slice();

    currentListeners.forEach((listener) => listener(value));
  };

  const updateValue = debounce(() => {
    if (value !== window.innerHeight) {
      value = window.innerHeight;
      notify();
    }
  }, 100);

  window.addEventListener('resize', updateValue);

  return {
    getValue() {
      return value;
    },

    subscribe(fn: Listener) {
      listeners.push(fn);

      const unsubscribe = () => {
        const index = listeners.indexOf(fn);

        listeners.splice(index, 1);
      };

      return unsubscribe;
    },
  };
};

let singleHeightObserver: ReturnType<typeof createHeightObserver> | null = null;

const getHeightObserver = () => {
  if (!singleHeightObserver) {
    singleHeightObserver = createHeightObserver();
  }

  return singleHeightObserver;
};

const useFullHeight = () => {
  const heightObserver = getHeightObserver();
  const [height, setHeight] = useState(heightObserver.getValue());

  useEffect(() => {
    const unsubscribe = heightObserver.subscribe(setHeight);

    return unsubscribe;
  }, [heightObserver]);

  return height;
};

export default useFullHeight;
