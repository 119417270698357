import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { saveEntities } from '../actions';
import adapter from './adapter';
import { Entities } from '../types';
import { SIMULATION } from './const';

export default createSlice({
  name: 'entities/simulations',
  initialState: adapter.getInitialState(),
  reducers: {
    deleteSimulation: adapter.removeOne,
    updateSimulation: adapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      saveEntities.type,
      (state, action: PayloadAction<Entities>) =>
        action.payload[SIMULATION]
          ? adapter.upsertMany(state, action.payload[SIMULATION])
          : state,
    );
  },
});
