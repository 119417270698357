import { TableCell, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { useTypedSelector } from 'src/redux';
import {
  EVENTS_NUMBER,
  EXECUTION_DATE,
  EXECUTION_DURATION,
} from 'src/redux/entities/simulationResults/const';
import { selectById } from 'src/redux/entities/simulationResults/selectors';
import { formatDate, formatToSeconds } from 'src/utils/format';

import { useStyles } from './styles';

type Props = {
  id: number;
  className?: string;
};

const LastResultsCell: FC<Props> = ({ id, className, ...rest }) => {
  const classes = useStyles();

  const simulationResult = useTypedSelector((state) => selectById(state, id))!;

  return (
    <TableCell {...rest} className={className}>
      {id && (
        <>
          <Typography variant="subtitle2" className={classes.row}>
            Execution date: <br />
            <span className={classes.text}>
              {formatDate(simulationResult[EXECUTION_DATE])}
            </span>
          </Typography>
          <Typography variant="subtitle2">
            Execution duration:{' '}
            <span className={classes.text}>
              {formatToSeconds(simulationResult[EXECUTION_DURATION])}
            </span>
          </Typography>
          <Typography variant="subtitle2">
            Number of events:{' '}
            <span className={classes.text}>
              {simulationResult[EVENTS_NUMBER]}
            </span>
          </Typography>
        </>
      )}
    </TableCell>
  );
};

export default LastResultsCell;
