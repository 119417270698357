import { Link } from '@material-ui/core';
import React, { FC } from 'react';

type Props = {
  href?: string;
};

const DownloadButton: FC<Props> = ({ href, children }) => {
  return (
    <Link target="_blank" rel="noreferrer noopener" href={href}>
      {children}
    </Link>
  );
};

export default DownloadButton;
