import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) => ({
    messageNoRecord: {
      display: 'flex',
      justifyContent: 'center',
      padding: `${spacing(4)}px 0`,
    },

    progress: {
      display: 'flex',
      justifyContent: 'center',
      padding: `${spacing(4)}px 0`,
    },

    isLoading: {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  }),
  { name: 'Table' },
);
