import { SIMULATIONS, HISTORY } from './ids';

export default [
  {
    id: SIMULATIONS,
    path: '/',
  },
  {
    id: HISTORY,
    path: '/history',
  },
];
