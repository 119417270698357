export const SIMULATION_VERSION = 'simulation_version';

export const ID = 'id';
export const DESCRIPTION = 'description';
export const DATE_CREATED = 'date_created';
export const LAST_RESULT = 'last_result';
export const NAME = 'name';
export const S3_URL = 's3_url';
export const SIMULATION = 'simulation';
export const VERSION = 'version';
