import {
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  CloudUpload,
  GetAppRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import React, { FC, useEffect, useState } from 'react';
import AddSimulationButton from 'src/components/AddSimulationButton';
import DownloadButton from 'src/components/DownloadButton';

import RemoveButton from 'src/components/RemoveButton';
import ResultsButton from 'src/components/ResultsButton';
import RunSimulationButton from 'src/components/RunSimulationButton';
import Table from 'src/components/Table';

import { useEffectState } from 'src/hooks/useAsyncEffect';
import { useTypedSelector } from 'src/redux';
import { useRunSimulation } from 'src/redux/entities/simulationResults/hooks';
import {
  DATE_CREATED,
  LAST_MODIFIED,
  LAST_RESULT,
  DESCRIPTION,
  NAME,
  SIMULATION_VERSIONS,
  S3_URL,
} from 'src/redux/entities/simulations/const';
import { useDeleteSimulation } from 'src/redux/entities/simulations/hooks';
import { selectById } from 'src/redux/entities/simulations/selectors';
import {
  useFindSimulationsVersion,
  useCreateSimulationVersion,
} from 'src/redux/entities/simulationVersions/hooks';
import { formatDate } from 'src/utils/format';

import LastResultsCell from './LastResultsCell';
import NestedContentRow from './NestedContentRow';
import NestedHeaderRow from './NestedHeaderRow';
import { useStyles } from './styles';

type Props = {
  id: number;
};

const ContentRow: FC<Props> = ({ id }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const findEffect = useFindSimulationsVersion();
  const deleteEffect = useDeleteSimulation();
  const runEffect = useRunSimulation();

  const { pending: deletePending } = useEffectState(deleteEffect);

  const simulation = useTypedSelector((state) => selectById(state, id))!;

  useEffect(() => {
    findEffect.run(simulation[SIMULATION_VERSIONS]);
  }, [findEffect, simulation]);

  return (
    <>
      <TableRow hover selected={open}>
        <TableCell padding="none">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{simulation[NAME]}</TableCell>
        <TableCell>{formatDate(simulation[DATE_CREATED])}</TableCell>
        <TableCell>{formatDate(simulation[LAST_MODIFIED])}</TableCell>

        <LastResultsCell id={simulation[LAST_RESULT]} />

        <TableCell align="center">
          {simulation[LAST_RESULT] && (
            <ResultsButton lastResultId={simulation[LAST_RESULT]} />
          )}
        </TableCell>

        <TableCell>{simulation[DESCRIPTION]}</TableCell>

        <TableCell align="right" className={classes.noWrap}>
          <DownloadButton href={simulation[S3_URL]}>
            <IconButton>
              <GetAppRounded />
            </IconButton>
          </DownloadButton>

          <RunSimulationButton
            id={simulation[SIMULATION_VERSIONS][0]}
            runEffect={runEffect}
          />

          <AddSimulationButton
            id={id}
            useCustomEffect={useCreateSimulationVersion}
            Button={(props: IconButtonProps) => <IconButton {...props} />}
          >
            <CloudUpload />
          </AddSimulationButton>

          <RemoveButton
            action={() => deleteEffect.run(id)}
            disabled={deletePending}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table
                size="small"
                aria-label="purchases"
                ids={simulation[SIMULATION_VERSIONS]}
                isLoading={false}
                HeadRow={NestedHeaderRow}
                ContentRow={NestedContentRow}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContentRow;
