import React, { FC, SyntheticEvent } from 'react';
import { useFormIsValid } from 'exeform';
import { Button } from '@material-ui/core';

type Props = {
  disabled?: boolean;
  onClick?(event: SyntheticEvent): void;
};

const SubmitButton: FC<Props> = ({ disabled = false, children, onClick }) => {
  const isValid = useFormIsValid();

  return (
    <Button
      disabled={!isValid || disabled}
      onClick={onClick}
      type="submit"
      variant="outlined"
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
