import { useForm, Errors } from 'exeform';

import { validateName, validateFiles } from 'src/utils/validators';

const initialValues = {
  name: '',
  description: '',
  file: null,
};

export type Values = typeof initialValues;

const validate = (values: Values) => {
  const errors: Errors = {};
  const name = validateName(values.name);
  const file = validateFiles(values.file);

  if (name) {
    errors.name = name;
  }
  if (file) {
    errors.file = file;
  }

  return errors;
};

const useAddSimulationForm = () => useForm({ initialValues, validate });

export default useAddSimulationForm;
