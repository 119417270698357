import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ spacing }) => ({
    root: {
      marginTop: spacing(4),
    },
  }),
  { name: 'Page' },
);
