export const validateName = (value: string) => {
  if (!value) {
    return 'Name is required';
  }

  return null;
};

export const validateFiles = (value: File | null) => {
  if (!value) {
    return 'Choose file to upload';
  }

  return null;
};
