import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexBasis: '100%',
      position: 'relative',
    },

    wrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto',
      padding: theme.spacing(3),
    },

    closeIcon: {
      position: 'absolute',
      right: 4,
      top: 4,
      zIndex: 2,
      color: 'inherit',

      '&:hover': {
        opacity: 0.8,
      },
    },
  }),
  { name: 'Dialog' },
);
