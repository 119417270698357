import { HISTORY, SIMULATIONS } from 'src/router/ids';

export const links = [
  {
    id: SIMULATIONS,
    label: 'Simulations',
  },
  {
    id: HISTORY,
    label: 'History',
  },
];
