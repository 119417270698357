import { useDispatch } from 'react-redux';
import { useAsyncEffect } from 'src/hooks/useAsyncEffect';
import { SIMULATION } from './const';

import { saveEntities } from '../actions';
import { CreateSimulationValues } from '../types';
import api from './api';
import simulationSlice from './slice';

export const useFindSimulations = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async () => {
    const { payload } = await api.find({
      include: 'simulation_versions,last_result',
    });

    dispatch(saveEntities(payload));
  });
};

export const useCreateSimulation = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (values: CreateSimulationValues) => {
    const simulation = await api.createRecord({
      [SIMULATION]: values,
    });

    dispatch(saveEntities(simulation));
  });
};

export const useDeleteSimulation = () => {
  const dispatch = useDispatch();

  return useAsyncEffect(async (id: number, controller) => {
    await api.deleteRecord(id);

    dispatch(simulationSlice.actions.deleteSimulation(id));
  });
};
