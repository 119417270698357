import { useRouterState } from '@routo/react';
import React, { FC, useEffect } from 'react';
import History from 'src/pages/History';
import Simulations from 'src/pages/Simulations';
import { HISTORY, SIMULATIONS } from 'src/router/ids';

import Header from '../Header';
import FullHeight from './FullHeight';

type Mapping = { [id: string]: FC };

const mapping: Mapping = {
  [SIMULATIONS]: Simulations,
  [HISTORY]: History,
};

const Router: FC = () => {
  const { id } = useRouterState();
  const Component = mapping[id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <FullHeight>
      <Header />
      <Component />
    </FullHeight>
  );
};

export default Router;
