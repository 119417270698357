import { TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';

const HeadRow: FC = () => (
  <TableRow>
    <TableCell />
    <TableCell align="left" padding="none">
      Name
    </TableCell>
    <TableCell>Date Created</TableCell>
    <TableCell>Last Modified</TableCell>
    <TableCell>Last results</TableCell>
    <TableCell>Results</TableCell>
    <TableCell align="center">Description</TableCell>
    <TableCell align="right">Actions</TableCell>
  </TableRow>
);

export default HeadRow;
