import { combineReducers } from '@reduxjs/toolkit';

import { SIMULATION_HISTORY } from './history/const';
import { SIMULATION_VERSION } from './simulationVersions/const';
import { SIMULATION } from './simulations/const';
import { SIMULATION_RESULT } from './simulationResults/const';

import historySlice from './history/slice';
import simulationsSlice from './simulations/slice';
import SimulationVersionsSlice from './simulationVersions/slice';
import simulationResultsSlice from './simulationResults/slice';

export default combineReducers({
  [SIMULATION]: simulationsSlice.reducer,
  [SIMULATION_HISTORY]: historySlice.reducer,
  [SIMULATION_VERSION]: SimulationVersionsSlice.reducer,
  [SIMULATION_RESULT]: simulationResultsSlice.reducer,
});
