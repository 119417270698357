import React, { FC, ElementType } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableHead,
  TableProps,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';
import Spinner from '../Spinner';

type Props = TableProps & {
  isLoading: boolean;
  ids: number[];
  HeadRow: ElementType;
  ContentRow: ElementType;
};

const Table: FC<Props> = ({
  isLoading = false,
  HeadRow,
  ContentRow,
  ids,
  ...tableProps
}) => {
  const classes = useStyles();

  if (!ids.length && !isLoading) {
    return (
      <Typography
        variant="h5"
        color="textSecondary"
        className={classes.messageNoRecord}
      >
        No items
      </Typography>
    );
  }
  const oneRow = ids.length === 1;

  return (
    <MuiTable {...tableProps}>
      <TableHead>
        <HeadRow />
      </TableHead>
      <TableBody className={clsx(isLoading && classes.isLoading)}>
        {ids.map((id: number) => (
          <ContentRow key={id} id={id} onlyOneRow={oneRow} />
        ))}
      </TableBody>
      {isLoading && <Spinner />}
    </MuiTable>
  );
};

export default Table;
