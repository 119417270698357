import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    dimmed: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
  { name: 'AddSimulationButton' },
);
