import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { saveEntities } from '../actions';
import { Entities } from '../types';
import adapter from './adapter';
import { SIMULATION_VERSION } from './const';

export default createSlice({
  name: 'entities/simulationVersions',
  initialState: adapter.getInitialState(),
  reducers: {
    deleteSimulationVersion: adapter.removeOne,
    updateSimulationVersion: adapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(
      saveEntities.type,
      (state, action: PayloadAction<Entities>) =>
        action.payload[SIMULATION_VERSION]
          ? adapter.upsertMany(state, action.payload[SIMULATION_VERSION])
          : state,
    );
  },
});
