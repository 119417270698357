import React, { FC } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RoutoLink } from '@routo/link';

import { useStyles } from './styles';

const Component: FC = (props) => (
  <MuiLink {...props} color="inherit" variant="subtitle1" underline="none" />
);

type Props = {
  id: string;
  label: string;
};

const Link: FC<Props> = ({ id, label }) => {
  const classes = useStyles();

  return (
    <RoutoLink
      key={id}
      to={id}
      className={classes.root}
      activeClassName={classes.active}
      component={Component}
    >
      {label}
    </RoutoLink>
  );
};

export default Link;
