import { AppBar, Container, Toolbar } from '@material-ui/core';
import React, { FC } from 'react';

import { links } from './consts';
import Link from './Link';
import Logo from './Logo';
import { useStyles } from './styles';

const Header: FC = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky">
      <Container maxWidth="lg">
        <Toolbar>
          <div className={classes.logoWrapper}>
            <Logo />
          </div>
          {links.map((link) => (
            <Link key={link.id} {...link} />
          ))}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
