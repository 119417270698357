import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  {
    '@global': {
      html: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        WebkitTextSizeAdjust: '100%',
        boxSizing: 'border-box',
      },

      '*, *::after, *::before': {
        boxSizing: 'inherit',
      },

      body: {
        margin: 0,
        backgroundColor: '#d0d7dc',
        fontFamily: 'Roboto, sans-serif',
      },
    },
  },
  { name: 'CssBaseline' },
);
