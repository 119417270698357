import React, { FC } from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

type Props = {
  className?: string;
};

const Page: FC<Props> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={clsx(className, classes.root)}>{children}</div>;
};

export default Page;
