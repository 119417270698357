export const SIMULATION = 'simulation';

export const ID = 'id';
export const DESCRIPTION = 'description';
export const DATE_CREATED = 'date_created';
export const LAST_MODIFIED = 'last_modified';
export const LAST_RESULT = 'last_result';
export const NAME = 'name';
export const S3_URL = 's3_url';
export const SIMULATION_VERSIONS = 'simulation_versions';
export const VERSIONS_COUNT = 'versions_count';
