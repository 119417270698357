import { SyntheticEvent, useEffect } from 'react';
import { omit } from 'remeda';

import { Effect, useEffectState } from 'src/hooks/useAsyncEffect';
import useUploadFile from 'src/hooks/useUploadFile';
import { S3_URL } from 'src/redux/entities/simulations/const';
import { CreateSimulationValues } from 'src/redux/entities/types';

import useAddSimulationForm from './form';

export const useData = (createEffect: Effect<CreateSimulationValues, void>) => {
  const form = useAddSimulationForm();

  const uploadFileEffect = useUploadFile();
  const { pending: uploadFilePending } = useEffectState(uploadFileEffect);
  const { pending: creatingPending } = useEffectState(createEffect);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    form.touchAllFields();

    if (form.isValid) {
      uploadFileEffect.run(form.values.file!);
    }
  };

  useEffect(() => {
    return uploadFileEffect.onDone(({ downloadUrl }) => {
      createEffect.run({
        ...omit(form.values, ['file']),
        [S3_URL]: downloadUrl as string,
      });
    });
  }, [createEffect, form.values, uploadFileEffect]);

  const pending = uploadFilePending || creatingPending;

  return { handleSubmit, uploadFileEffect, pending, form };
};
