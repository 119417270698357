import axios, { AxiosRequestConfig } from 'axios';
import { pick } from 'remeda';
import config from 'src/config';

export type Results<T> = { payload: T; meta: { total: number } };

export const request = (options: AxiosRequestConfig) => {
  return axios({
    baseURL: config.apiUrl,
    ...options,
  }).then(pick(['data', 'headers']));
};

export const createApi = <T, R>(name: string) => {
  const url = `${config.apiUrl}/${name}`;

  return Object.freeze({
    find(params?: object): Promise<Results<T>> {
      return axios.get(url, { params }).then((res) => res.data);
    },

    findRecord(id: number): Promise<T> {
      return axios.get(`${url}/${id}`).then((res) => res.data);
    },

    deleteRecord(id: number): Promise<T> {
      return axios.delete(`${url}/${id}`).then((res) => res.data);
    },

    // createRecord(record: Partial<T>): Promise<R> {
    createRecord(record: { [K in keyof T]?: Partial<R> }): Promise<T> {
      return axios.post(`${url}`, record).then((res) => res.data);
    },
  });
};
