import {
  Button,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, SyntheticEvent } from 'react';

import {
  EVENTS_NUMBER,
  EXECUTION_DATE,
  EXECUTION_DURATION,
  S3_URL,
} from 'src/redux/entities/simulationResults/const';
import { SimulationResult } from 'src/redux/entities/simulationResults/types';
import { formatDate, formatToSeconds } from 'src/utils/format';

import Dialog from '../Dialog';
import DialogContent from '../DialogContent';
import DownloadButton from '../DownloadButton';
import Spinner from '../Spinner';
import { useStyles } from './styles';

type Props = {
  open: boolean;
  simulationResult?: SimulationResult;
  handleClose(event: SyntheticEvent): void;
  isLoading?: boolean;
};

const ResultsDialog: FC<Props> = ({
  handleClose,
  open,
  isLoading = false,
  simulationResult,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Simulation results</DialogTitle>
      <DialogContent className={clsx(isLoading && classes.dimmed)}>
        <Typography variant="subtitle2" color="textPrimary">
          Execution date:{' '}
          <span className={classes.text}>
            {simulationResult && formatDate(simulationResult[EXECUTION_DATE])}
          </span>
        </Typography>
        <Typography variant="subtitle2" color="textPrimary">
          Execution duration:{' '}
          <span className={classes.text}>
            {simulationResult &&
              formatToSeconds(simulationResult[EXECUTION_DURATION])}
          </span>
        </Typography>
        <Typography variant="subtitle2" color="textPrimary">
          Number of events:{' '}
          <span className={classes.text}>
            {simulationResult && simulationResult[EVENTS_NUMBER]}
          </span>
        </Typography>
      </DialogContent>

      <DialogActions className={clsx(isLoading && classes.dimmed)}>
        <DownloadButton href={simulationResult && simulationResult[S3_URL]}>
          <Button color="primary" variant="outlined">
            Download
          </Button>
        </DownloadButton>
      </DialogActions>

      {isLoading && <Spinner />}
    </Dialog>
  );
};

export default ResultsDialog;
