import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#e6ecf3',
      },
    },

    MuiTableHead: {
      root: {
        whiteSpace: 'nowrap',
      },
    },

    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: 'rgba(167, 64, 40, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(167, 64, 40, 0.1)',
          },
        },
      },
    },
  },

  props: {
    MuiTextField: {
      variant: 'outlined',
    },
  },
});
