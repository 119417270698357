import axios from 'axios';
import { request } from 'src/utils/api';

import { useAsyncEffect } from '../useAsyncEffect';
import { createFormData } from './utils';

const config = {
  headers: {
    'Content-Type': 'application/zip; multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, PATCH, OPTIONS',
    Accept: 'application/zip; multipart/form-data',
  },
};

const useUploadFile = () => {
  return useAsyncEffect(async (file: File) => {
    const {
      data: {
        upload_url: { url: uploadUrl, fields },
        download_url: downloadUrl,
      },
    } = await request({
      url: '/generate-presigned-url/',
      method: 'POST',
      data: {
        filename: file.name,
      },
    });

    const formData = createFormData(file, fields);

    await axios.post(uploadUrl, formData, config);

    return { downloadUrl };
  });
};

export default useUploadFile;
